import React, { useEffect, useState } from "react";
import logo from "./images/GMA_LOGO_CIRCLE.png";
import classes from "./Nav.module.css";
function Nav(props) {
  const gmaLink = "http://www.govantesmusicacademy.com/";
  // const emailRef = useRef();
  // const passwordRef = useRef();

  const [isClicked, SetIsClicked] = useState(false);
  useEffect(() => {
    if (isClicked) {
      // Perform your action here
      console.log("Action performed");
      SetIsClicked(true);
      // setPerformAction(false); // Reset the flag
    }
  }, [isClicked, SetIsClicked]);

  return (
    <div>
      <a href={gmaLink} target="_blank" rel="noopener noreferrer">
        <img
          className={classes.logo}
          target="_blank"
          rel="noopener noreferrer"
          src={logo}
          alt=""
        />
      </a>
    </div>
  );
}

export default Nav;
