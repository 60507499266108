import React, { useRef, useState } from "react";
// import classes from "./formEN.module.css";

import classes from "./formES.module.css";

function Form({ onSetFormSent }) {
  const [formSent, setFormSent] = useState(false);

  const usernameRef = useRef();
  const emailRef = useRef();
  const ageRef = useRef();

  const formatDateToDayMonth = (date) => {
    const day = date.getDate().toString().padStart(2, "0"); // Ensures the day is two digits
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() is zero-based; +1 to adjust

    return `${day}-${month}`;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    function capitalizeFirstLetter(text) {
      // Check if the input text is empty or null
      if (!text) {
        return text;
      }

      // Capitalize the first letter and concatenate the rest of the string
      return text.charAt(0).toUpperCase() + text.slice(1);
    }

    const name = capitalizeFirstLetter(usernameRef.current.value.toLowerCase());
    const email = emailRef.current.value.toLowerCase();
    const childage = parseInt(ageRef.current.value, 10);
    // const language = "English";

    // Check if childAge is not a number
    if (isNaN(childage)) {
      // Handle the invalid input
      console.error("Child age is not a valid number");
      return; // Exit the function to prevent the form submission
    }
    const language = "Spanish";

    const timestamp = new Date();

    const formatDateToDayMonthHourMin = (date) => {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");

      return `${day}-${month} ${hours}:${minutes}`;
    };

    const formattedDate = formatDateToDayMonthHourMin(timestamp);

    const formData = { name, email, childage, language };
    setFormSent(true);
    onSetFormSent(true);
    try {
      const response = await fetch(
        "https://ifrmph0lp5.execute-api.us-east-1.amazonaws.com/send",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      // console.log(formData);

      if (response.ok) {
        setFormSent(true);
      } else {
        console.error("Form submission failed");
        setFormSent(false);
        // Handle errors
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle network errors
    }
  };

  return (
    <main>
      {formSent && (
        <div className={classes.ThankUMessage}>
          <p>
            {/* <strong>
              Please, share this opportunity with other families in our
              community that would be interested in joining us to make it
              possible!
            </strong> */}
            <strong>
              Por favor, comparte esta oportunidad con otras familias de nuestro
              comunidad que estaría interesada en unirse a nosotros para hacerlo
              posible!
            </strong>
            <br />
            {/* Stay tuned for more information soon! */}
            ¡Esté atento a nustros emails para obtener más información!
          </p>
        </div>
      )}

      {/* <form
        action="https://hook.us1.make.com/6wh5jza6ly6ct3x1f3v5ehst9hcvodv8"
        method="post"
      >
        <input type="text" name="username" placeholder="Username" />
        <input type="email" name="email" placeholder="Email" />
        <button type="submit">Submit</button>
      </form>


       */}
      {!formSent && (
        <form
          onSubmit={handleSubmit}
          className={classes.signUpForm}
          method="POST"
        >
          <div className="mb-3">
            <label htmlFor="parentsEmail" className="form-label">
              Email
            </label>

            <input
              type="email"
              ref={emailRef}
              className="form-control"
              id="parentsEmail"
              aria-describedby="emailHelp"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="parentsName" className="form-label">
              {/* Parent's Name / Tutor's Name */}
              Nombre del Padre/Madre/Tutor
            </label>

            <input
              type="text"
              ref={usernameRef}
              className="form-control"
              id="parentsName"
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="childage" className="form-label">
              {/* Child's Age */}
              Edad del Niño
            </label>
            <input
              type="number"
              ref={ageRef}
              className="form-control"
              id="childage"
              min="0"
              max="18"
              required
            />
          </div>

          <button
            onKeyUp={handleSubmit}
            type="submit"
            className="btn btn-primary"
          >
            {/* SEND INFO */}
            ENVIAR
          </button>
        </form>
      )}
    </main>
  );
}

// const jsonData = JSON.stringify(dataBase);

export default Form;
