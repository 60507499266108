import React, { useState } from "react";

import heroPicMobile from "./images/sizes/choir.2795d856d0ee6fbcbf02_640.webp";
import heroPicMid from "./images/sizes/choir.2795d856d0ee6fbcbf02_1024.webp";
// import heroPicLarge from "./images/sizes/choir.2795d856d0ee6fbcbf02_Original.webp";

import choirKidsSmall from "./images/sizes/bg_kids.552093830dbb6ad4fd1_small.webp";
// import choirKidsMid from "./images/sizes/bg_kids.552093830dbb6ad4fd1e_mid.webp";
import choirKidsLarge from "./images/sizes/bg_kids.552093830dbb6ad4fd1e_large.webp";

import Nav from "./Nav";

import Form from "./formES";
import claudiaHeadShotMid from "./images/sizes/claudia-headshot2020-02-scaled.ce30919bae36854d58e9_mid.webp";
import claudiaHeadShotLarge from "./images/sizes/claudia-headshot2020-02-scaled.ce30919bae36854d58e9_large.webp";

import classes from "./childrenschoir_ES.module.css";

function ChildrensChoir() {
  const [formSent, setFormSent] = useState(false);

  const handleLearnMore = () => {
    window.open("http://www.govantesmusicacademy.com/", "_blank");
  };

  return (
    <main className={classes.main}>
      <section className={classes.section}>
        <Nav />
        <hr style={{ marginTop: "0px" }} />
        <div className={classes.block}></div>

        <div className={classes.containerGrid}>
          {/* 1  headlines */}
          {/* <div className={classes.gridItem}> */}
          <div className={`${classes.headlines} ${classes.gridItem}`}>
            <h1 className={`${classes.padT} ${classes.HeadLine}`}>
              Un coro de niños para nuestra comunidad
            </h1>
            <p className={`${classes.padB} ${classes.subHeadLine}`}>
              Inwood & Washington Heights (NYC)
            </p>
          </div>

          {/* </div> */}
          {/* 2  hero pic */}

          {/* MOBILE PICTURE */}
          <img
            id="imageOpen"
            className={`${classes.hero} ${classes.displayTuggleMobile}`}
            src={heroPicMobile}
            alt="choir"
          />

          <img
            id="imageOpen"
            className={`${classes.hero} ${classes.displayTuggleIpad}`}
            src={heroPicMid}
            alt="choir"
          />

          {/* 3 join with form*/}
          <div className={classes.joinForm}>
            <hr style={{ marginTop: "0px" }} />

            {formSent && (
              <h3
                className={`${classes.joinTittle} ${classes.padB} ${classes.displayTuggleIpad}`}
              >
                Muchas Gracias!
              </h3>
            )}
            {!formSent && (
              <h3
                className={`${classes.joinTittle} ${classes.padB} ${classes.displayTuggleIpad}`}
              >
                Únete!
              </h3>
            )}

            <div className={`${classes.padB} ${classes.displayTuggleIpad}`}>
              <Form onSetFormSent={setFormSent} />
            </div>
          </div>
          {/* 4 description */}
          <div>
            <div className={`${classes.gridItem} ${classes.displayTuggleIpad}`}>
              <hr style={{ marginTop: "0px" }} />

              <p>
                Nuestro propósito es crear un Coro de Niños para nuestra
                comunidad de Inwood y Washington Heights, una iniciativa
                diseñada para reunir las voces de los más pequeños en
                celebración de la herencia y la cultura latina, fomentando un
                ambiente de aprendizaje alegre y de exploración musical. Por
                favor, complete el formulario y presione el botón ENVIAR para
                informarnos sobre su interés en ser parte.
              </p>
            </div>
          </div>
        </div>

        {/* // MOBILE FORM */}
        <div className={classes.gridItem}>
          {formSent && (
            <h3
              className={`${classes.joinTittle} ${classes.padB} ${classes.padT} ${classes.displayTuggleMobile}`}
            >
              Muchas Gracias!
            </h3>
          )}
          {!formSent && (
            <h3
              className={`${classes.joinTittle} ${classes.padB} ${classes.padT} ${classes.displayTuggleMobile}`}
            >
              Únete!
            </h3>
          )}

          <div className={`${classes.padB} ${classes.displayTuggleMobile}`}>
            <Form onSetFormSent={setFormSent} />
          </div>
        </div>
        <div className={`${classes.gridItem} ${classes.displayTuggleMobile}`}>
          <p>
            Nuestro propósito es crear un Coro de Niños para nuestra comunidad
            de Inwood y Washington Heights, una iniciativa diseñada para reunir
            las voces de los más pequeños en celebración de la herencia y la
            cultura latina, fomentando un ambiente de aprendizaje alegre y de
            exploración musical. Por favor, complete el formulario y presione el
            botón ENVIAR para informarnos sobre su interés en ser parte.
          </p>
        </div>

        <div className={classes.block}></div>

        <hr style={{ marginTop: "0px" }} />

        <div className={classes.block}></div>

        <img
          id="imageOpen"
          className={`${classes.images} ${classes.padB} ${classes.displayTuggleMobile}`}
          src={choirKidsSmall}
          alt="choir"
        />
        <img
          id="imageOpen"
          className={`${classes.images} ${classes.padB} ${classes.displayTuggleIpad}`}
          src={choirKidsLarge}
          alt="choir"
        />

        <p>
          Este coro tiene como objetivo unir a los niños a través del poder de
          la música, permitiendo que profundicen en sus raíces latinas y al
          mismo tiempo creen amistades y experiencias memorables.
        </p>
        <div className={classes.block}></div>

        <hr />

        <div className={classes.block}></div>

        <h3 className={classes.padB}>
          Beneficios de la participación en el coro para niños
        </h3>

        <div className={classes.benefitsContainer}>
          <div>
            <h5>Enriquecimiento cultural e identidad</h5>
            <p>
              Comprender y celebrar sus raíces culturales a través de la música.
              puede mejorar su autoestima y proporcionar una conexión más fuerte
              con su comunidad y su patrimonio.
            </p>
          </div>
          <div>
            <h5>Crecimiento social y emocional</h5>
            <p>
              Cantar en un coro ofrece una salida emocional, permitiendo a los
              niños expresarse, controlar el estrés y experimentar alegría y
              satisfacción en un ambiente de apoyo.
            </p>
          </div>

          <div>
            <h5>Mejora cognitiva y académica</h5>
            <p>
              La educación musical se ha relacionado con un mejor rendimiento en
              áreas como matemáticas y lectura, ya que implica comprender
              patrones, ritmo y conteo.{" "}
            </p>
          </div>
        </div>
        <div className={classes.block}></div>

        <hr />
        <div className={classes.block}></div>

        <div className={classes.bioClaudia}>
          <div>
            <h3 className={classes.padB}>¿Quién quiere crear el coro?</h3>
            <p className={classes.displayTuggleIpad}>
              Claudia Govantes es una premiada directora de coro, pianista y
              Cantante, fundadora de{" "}
              <a href="http://www.govantesmusicacademy.com/">
                <strong>Govantes Music Academy</strong>{" "}
              </a>{" "}
              que imparte lecciones de piano y voz en Inwood, Nueva York. <br />
              Claudia fue primera soprano del Coro Nacional de Cuba, así como de
              varios coros durante más de 20 años de carrera realizando giras
              por países como Alemania, España, Venezuela, Colombia, Ecuador y
              otros. <br />
              Con este nuevo proyecto, Claudia quiere llevar la música coral a
              la comunidad enfocándose en nuestras raíces y herencia latina.
            </p>
            <button
              onClick={handleLearnMore}
              className={`btn btn-primary mb-3 ${classes.displayTuggleIpad}`}
              // href="http://www.govantesmusicacademy.com/"
            >
              Aprende mas de GMA
            </button>
          </div>

          <div>
            <img
              className={`${classes.claudiaHeadShot} ${classes.padB} ${classes.displayTuggleIpad}`}
              src={claudiaHeadShotLarge}
              alt="Claudia_Govantes"
            />
          </div>

          <img
            className={`${classes.claudiaHeadShot} ${classes.padB} ${classes.displayTuggleMobile}`}
            src={claudiaHeadShotMid}
            alt="Claudia_Govantes"
          />
          <p className={classes.displayTuggleMobile}>
            Claudia Govantes es una premiada directora de coro, pianista y
            Cantante, fundadora de{" "}
            <a href="http://www.govantesmusicacademy.com/">
              <strong>Govantes Music Academy</strong>{" "}
            </a>{" "}
            que imparte lecciones de piano y voz en Inwood, Nueva York. <br />
            Claudia fue primera soprano del Coro Nacional de Cuba, así como de
            varios coros durante más de 20 años de carrera realizando giras por
            países como Alemania, España, Venezuela, Colombia, Ecuador y otros.{" "}
            <br />
            Con este nuevo proyecto, Claudia quiere llevar la música coral a la
            comunidad enfocándose en nuestras raíces y herencia latina.
          </p>

          <button
            className={`btn btn-primary mb-3 ${classes.displayTuggleMobile}`}
            onClick={handleLearnMore}
          >
            Learn More abour GMA
          </button>
        </div>
        <div className={classes.block}></div>
        <hr />
        <div className={classes.block}></div>

        {formSent && <h3 className={classes.padB}> Thank You!</h3>}
        {!formSent && (
          <h3 className={classes.padB}>
            ¿Aún no te has unido a nosotros? <br /> Puedes llenar este
            formulario!
          </h3>
        )}

        <div className={`${classes.padB} ${classes.formTwo}`}>
          <Form onSetFormSent={setFormSent} />
        </div>
      </section>
      <footer className={classes.footer}>
        <div>
          <p className={classes.footerText}>
            Esta es una iniciativa de la Govantes Music Academy para crear un
            coro para la comunidad de Inwood y Washington Heights en la ciudad
            de Nueva York. Si estás interesado en ayudarnos de alguna manera,
            por favor contáctanos a:{" "}
            <a
              href="mailto:govantesmusicacademy@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              govantesmusicacademy@gmail.com
            </a>
          </p>
        </div>
      </footer>
    </main>
  );
}

export default ChildrensChoir;
